<template>
  <div class="rightLink">
    <div class="bottomDiv">
      <div class="title">待分配设备</div>
      <div class="formDiv">
        <el-form label-position="right" label-width="100px">
          <el-row :gutter="20">
            <el-col :span="9">
              <el-form-item label="设备编号:">
                <el-input
                  v-model="FormData.device_id"
                  placeholder="请输入设备编号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="设备类型:">
                <el-select
                  v-model="FormData.type_id"
                  placeholder="请选择设备类型"
                  clearable
                >
                  <el-option
                    v-for="car in carList"
                    :key="car.value"
                    :value="car.value"
                    :label="car.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <div class="BtnSearch" @click="searchBtn()">查询</div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 表格部分 -->
      <div class="tableDiv">
        <el-table
          ref="multipleTableRef"
          :data="tableData"
          border
          v-loading="loading"
          height="480px"
          style="
            width: 90%;
            margin: 20px auto;

            overflow-y: auto;
          "
        >
          <el-table-column label="序号" type="index" align="center" width="60">
            <template #default="{ $index }">
              {{ (currentPage - 1) * pageSize + $index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="设备编号" prop="device_id" />
          <!-- <el-table-column label="站点名称" prop="station_name" width="150" /> -->
          <el-table-column label="设备类型" prop="type_id">
            <template #default="scope">
              <span v-if="scope.row.type_id == 2">电动车</span>
              <span v-if="scope.row.type_id == 4">电动汽车</span>
            </template>
          </el-table-column>

          <el-table-column label="功率" prop="wx_id" />
          <el-table-column label="状态" prop="state" />
          <el-table-column label="操作" width="220" align="center">
            <template #default="scope">
              <el-button
                round
                color="#42a7a9"
                plain
                size="mini"
                @click="binduser(scope.$index, scope.row)"
                >分配运营商</el-button
              >
              <el-button
                round
                type="warning"
                plain
                size="mini"
                @click="delcharg(scope.$index, scope.row)"
                >删除</el-button
              >
              <!-- <div
                  class="BtnSearch1 BtnSearch"
                  style="width:100px"
                  @click="binduser(scope.$index, scope.row)"
                  >分配运营商</div
                >
                <div
                  class="BtnSearch1 BtnSearch"
                  @click="delcharg(scope.$index, scope.row)"
                  >删除</div
                >
                 -->
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page="FormData.page"
            :page-size="pageSize"
            :small="small"
            layout="total, slot, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
            <template #default>
              <span class="el-pagination__total">{{ Math.ceil(total / pageSize) }} 页 </span>
            </template>
          </el-pagination>
        </div>
      </div>

      <!-- 编辑 -->
      <div v-dialogdrag>
        <el-dialog
          v-model="Dialog.editLevy"
          title="分配运营商"
          width="820px"
          :before-close="closeDialog"
          class="dialog"
        >
          <div class="formDiv1">
            <el-form
              ref="FormRules"
              :rules="rules"
              :model="FormData"
              label-position="right"
              label-width="100px"
            >
              <el-row :gutter="20">
                <el-col :span="9">
                  <el-form-item label="设备编号:">
                    <el-input
                      v-model="Dialog.DialogData.device_id"
                      disabled
                      placeholder="请输入站点名称"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="9">
                  <el-form-item label="运营商:">
                    <el-select
                      v-model="Dialog.DialogData.username"
                      placeholder="请选择运营商"
                      style="width: 100%"
                    >
                      <el-option
                        v-for="aa in users"
                        :key="aa.username"
                        :value="aa.username"
                        :label="aa.username"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button class="Btn" @click="closeDialog">取消</el-button>
              <el-button class="Btn" type="primary" @click="submitData"
                >提交</el-button
              >
            </span>
          </template>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
  <script>
// import { ref } from 'vue'
import { onMounted, reactive, toRefs } from "vue-demi";
import {
  no_binding,
  operator_data,
  allot_device,
  remove_device,
} from "@/request/api";
import { ElMessageBox, ElMessage } from "element-plus";
// import ProvinceCityCountry from '../../assets/json/address'
// import {  regionData } from 'element-china-area-data'
export default {
  name: "EditArea",
  setup() {
    const data = reactive({
      //表单数据
      FormData: {
        username: localStorage.getItem("username"),
        page: 1,
      },
      tableData: [],
      carList: [
        {
          value: 2,
          label: "电动车",
        },
        {
          value: 4,
          label: "电动汽车",
        },
      ],
      users: [],
      // options: regionData,
      // selectedOptions: "",

      //   弹框
      Dialog: {
        editLevy: false,
        DialogData: {},
      },

      loading: false,
      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      checked: 0,
      small: true,
    });
    const searchBtn = () => {
      data.FormData.page = 1;
      getList();
    };

    const binduser = (index, row) => {
      console.log("绑定运营商");
      data.Dialog.editLevy = true;
      data.Dialog.DialogData = JSON.parse(JSON.stringify(row));
    };

    const delcharg = (index, row) => {
      console.log(row, "0000");
      ElMessageBox.confirm("您确认删除此设备吗？", "警告", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const dataa = {
          id: row.id,
        };
        remove_device(dataa)
          .then((res) => {
            if (res.code == 200) {
              ElMessage.success(res.msg);
              getList();
            } else {
              ElMessage.error("删除失败");
            }
          })
          .catch(() => {
            ElMessage.error("操作失败");
          });
      });
    };

    const closeDialog = () => {
      data.Dialog.editLevy = false;
    };
    const closeDialog1 = () => {
      data.Dialog1.editLevy = false;
    };

    const submitData = () => {
      allot_device(data.Dialog.DialogData).then((res) => {
        console.log(res);
        if (res) {
          console.log(res, "设备绑定运营商");
          data.loading = false;
          if (res.code == 200) {
            data.Dialog.editLevy = false;
            ElMessage.success(res.msg);
            getList();
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("绑定失败！");
        }
      });
    };

    const getList = () => {
      // const dataa = data.FormData
      // console.log(dataa,'aaaadataa');

      no_binding(data.FormData).then((ress) => {
        // console.log(ress,'000------');
        // data.loading = true
        if (ress) {
          // console.log(ress,'111------');
          // console.log(ress.data,'设备列表333');
          data.loading = false;
          if (ress.code == 200) {
            data.tableData = [];
            ress.data.forEach((a) => {
              // console.log(a,'aaaaa');
              if (a.operator_id == null) {
                data.tableData.push(a);
                // ress.data.splice(a)
              }
            });
            // data.tableData = res.data
            // console.log(data.tableData,'0000')
            data.total = data.tableData.length;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const getUsername = () => {
      operator_data().then((res) => {
        console.log(res, "sssssss");
        data.users = res;
      });
    };

    const handleChange = (value) => {
      console.log(value, "value");
    };

    const handleSizeChange = (val) => {
      data.pageSize = val;
    };
    const handleCurrentChange = (val) => {
      data.FormData.page = val;
      getList();
      //
    };

    onMounted(() => {
      console.log(data);
      getList();
      getUsername();
    });
    return {
      ...toRefs(data),
      searchBtn,
      closeDialog,
      closeDialog1,
      submitData,
      getList,
      getUsername,
      binduser,
      delcharg,
      handleChange,
      handleSizeChange,
      handleCurrentChange,
    };
  },
};
</script>
  <style scoped>
.formDiv {
  margin-top: 10px;
  height: 80px;
}
.formDiv1 {
  margin-top: 10px;
  height: 120px;
}
.bottomDiv {
  height: 100%;
}

.btn:focus {
  background-color: #03beb0;
  border-color: #03beb0;
}

.BtnSearch1 {
  float: left;
  width: 80px;
  margin: 0 4px;
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: -10px;
}
</style>
  